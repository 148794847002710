(function ($) {
    "use strict";
    var $window = $(window);
    $('#preloader').fadeOut('normal', function () {
        $(this).remove();
    });
    $window.on('scroll', function () {
        var scroll = $window.scrollTop();
        var logocommon = $(".navbar-brand img");
        if (scroll <= 50) {
            $("header").removeClass("scrollHeader").addClass("fixedHeader");
            logocommon.attr('src', '/images/logo.png');
        } else {
            $("header").removeClass("fixedHeader").addClass("scrollHeader");
            logocommon.attr('src', '/images/logo.png');
        }
    });
    $window.on('scroll', function () {
        if ($(this).scrollTop() > 500) {
            $(".scroll-to-top").fadeIn(400);
        } else {
            $(".scroll-to-top").fadeOut(400);
        }
    });
    $(".scroll-to-top").on('click', function (event) {
        event.preventDefault();
        $("html, body").animate({scrollTop: 0}, 600);
    });
    var pageSection = $(".parallax,.bg-img");
    pageSection.each(function () {
        if ($(this).attr("data-background")) {
            $(this).css("background-image", "url(" + $(this).data("background") + ")");
        }
    });
    // $('.story-video').magnificPopup({delegate: '.video', type: 'iframe'});
    $window.resize(function (event) {
        setTimeout(function () {
            SetResizeContent();
        }, 500);
        event.preventDefault();
    });

    function fullScreenHeight() {
        var element = $(".full-screen");
        var $minheight = $window.height();
        element.css('min-height', $minheight);
    }

    function ScreenFixedHeight() {
        var $headerHeight = $("header").height();
        var element = $(".screen-height");
        var $screenheight = $window.height() - $headerHeight;
        element.css('height', $screenheight);
    }

    function SetResizeContent() {
        fullScreenHeight();
        ScreenFixedHeight();
    }

    SetResizeContent();
    $( document ).ready(function() {
        var owl_testimonial = $('.testimonial-style1');
        $('.testimonial-style1').owlCarousel({
            loop: true,
            responsiveClass: true,
            nav: false,
            dots: false,
            margin: 0,
            autoplay: true,
            thumbs: true,
            thumbsPrerendered: true,
            autoplayTimeout: 5000,
            smartSpeed: 800,
            responsive: {0: {items: 1}, 600: {items: 1}, 1000: {items: 1}},
        });
        owl_testimonial.on('change.owl.carousel', function (event) {
            var item = event.item.index - 4;
            $('.owl-thumb-item').not('.cloned').eq(item).addClass('active');
            $('.owl-thumb-item').not('.cloned').eq(item-1).removeClass('active');
        });
        $('.testimonial-style2').owlCarousel({
            loop: true,
            responsiveClass: true,
            nav: true,
            dots: false,
            margin: 40,
            autoplay: true,
            thumbs: false,
            thumbsPrerendered: false,
            autoplayTimeout: 5000,
            smartSpeed: 800,
            responsive: {0: {items: 1}, 768: {items: 2}, 992: {items: 2}}
        });
        $('.gallery-carousel').owlCarousel({
            loop: true,
            responsiveClass: true,
            nav: true,
            dots: false,
            margin: 40,
            autoplay: true,
            thumbs: false,
            thumbsPrerendered: false,
            autoplayTimeout: 5000,
            smartSpeed: 800,
            responsive: {0: {items: 1}, 768: {items: 1}, 992: {items: 1}}
        });
        $('.courses-area .owl-carousel').owlCarousel({
            loop: true,
            responsiveClass: true,
            autoplay: false,
            smartSpeed: 500,
            nav: false,
            navText: ["<i class='fas fa-long-arrow-alt-left'></i>", "<i class='fas fa-long-arrow-alt-right'></i>"],
            dots: true,
            margin: 10,
            responsive: {0: {items: 1, margin: 0, dots: false}, 768: {items: 2}, 992: {items: 3}}
        });
        // $('.facility-wrapper .owl-carousel').owlCarousel({
        //     loop: true,
        //     responsiveClass: true,
        //     autoplay: false,
        //     smartSpeed: 500,
        //     nav: false,
        //     dots: false,
        //     margin: 30,
        //     responsive: {0: {items: 2}, 768: {items: 3}, 992: {items: 4}, 1200: {items: 4}}
        // });
        $('.slider-fade .owl-carousel').owlCarousel({
            items: 1,
            loop: true,
            dots: false,
            margin: 0,
            nav: true,
            navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
            autoplay: false,
            smartSpeed: 500,
            mouseDrag: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut'
        });
        $('.banner-slider .owl-carousel').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            margin: 0,
            nav: false,
            autoplay: true,
            smartSpeed: 500,
            mouseDrag: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut'
        });
        $('.owl-carousel').owlCarousel({items: 1, loop: true, dots: false, margin: 0, autoplay: true, smartSpeed: 500});
        var owl = $('.slsider-fade');
        owl.on('changed.owl.carousel', function (event) {
            var item = event.item.index - 2;
            console.log(item);
            $('h3').removeClass('animated fadeInRight');
            $('h1').removeClass('animated fadeInRight');
            $('p').removeClass('animated fadeInRight');
            $('.butn').removeClass('animated fadeInRight');
            $('.owl-item').not('.cloned').eq(item).find('h3').addClass('animated fadeInRight');
            $('.owl-item').not('.cloned').eq(item).find('h1').addClass('animated fadeInRight');
            $('.owl-item').not('.cloned').eq(item).find('p').addClass('animated fadeInRight');
            $('.owl-item').not('.cloned').eq(item).find('.butn').addClass('animated fadeInRight');
        });
        if ($(".horizontaltab").length !== 0) {
            $('.horizontaltab').easyResponsiveTabs({
                type: 'default', width: 'auto', fit: true, tabidentify: 'hor_1', activate: function (event) {
                    var $tab = $(this);
                    var $info = $('#nested-tabInfo');
                    var $name = $('span', $info);
                    $name.text($tab.text());
                    $info.show();
                }
            });
        }
        $('#admission').countdown({
            date: '07/18/2024 12:00:00',
            offset:2
        }, function () {});

        $("#gallery_btn").click(function (){
           $("#gallery").fadeToggle();
        });
    });
    $window.on("load", function () {
        $('.gallery').magnificPopup({delegate: '.popimg', type: 'image', gallery: {enabled: true}});
        var $gallery = $('.gallery').isotope({});
        $('.filtering').on('click', 'span', function () {
            var filterValue = $(this).attr('data-filter');
            $gallery.isotope({filter: filterValue});
        });
        $('.filtering').on('click', 'span', function () {
            $(this).addClass('active').siblings().removeClass('active');
        });
        // $window.stellar();
    });
})(jQuery);
